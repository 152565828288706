interface AdsFinishParse {
    detail: {
        evtName: string;
        positions: {
            [propName: string]: {
                validAd: boolean;
            };
        };
    };
}

// valid ad position to check
const validAds = ['XFPAD', 'WFPAD'];

// parses ntk to remove gemini ad and related indicator element
const removeGeminiAd = () => {
    const ntkModule = document.getElementById('module-ntk');

    if (!ntkModule) {
        return;
    }
    const adEl = ntkModule.getElementsByClassName('ntk-ad-item')[0];
    const scrollviewEle =
        ntkModule.getElementsByClassName('wafer-scrollview')[0];

    if (!adEl || !scrollviewEle) {
        return;
    }

    const removeSlide = () => {
        const waferScroll = window.wafer.utils.getWaferInstanceForElem(
            scrollviewEle,
            'wafer-scrollview'
        );
        waferScroll?.instance.removeSlide(adEl);
    };
    window.wafer.ready(function scrollviewchange() {
        try {
            removeSlide();
        } catch (e) {
            window.wafer.on('scrollview:ready', (data) => {
                if (
                    data.elem.classList?.contains(
                        'scrollview-with-dot-pagination'
                    )
                ) {
                    removeSlide();
                }
            });
        }
    });
};

/**
 * Hide Gemini ad in NTK when XFPAD/WFPAD is valid ad
 * @param {AdsFinishParse} data Ads data object
 */
export default (data: AdsFinishParse): void => {
    let validDisplayAd = false;

    // ensure ads are valid
    for (const pos of validAds) {
        // check custom event data payload
        if (data.detail.positions?.[pos]?.validAd) {
            validDisplayAd = true;
            break;
        }
        // otherwise check `window.pageloadValidAds`, which is an array of valid ad positions
        if (window.pageloadValidAds?.includes(pos)) {
            validDisplayAd = true;
            break;
        }
    }

    if (window.wafer && validDisplayAd) {
        removeGeminiAd();
    }
};
