import ntkAdsCheck from './sda/ntkAdsCheck';
import { rapidBeaconClick } from './utils/rapid';
const { wafer } = window;

wafer?.ready(() => {
    // handle wafer autocomplete select and delete instrumentation
    wafer.on('autocomplete:select', rapidBeaconClick);
    wafer.on('autocomplete:deleted', rapidBeaconClick);
});

// @ts-ignore handle ntk gemini / display add logic
window.addEventListener('ads:finish:parse', ntkAdsCheck, { once: true });
